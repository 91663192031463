import axiosRetry from 'axios-retry';

const moment = require('moment');
const axiosBase = require('axios');

export class SystemLib {
  // システムモード
  systemMode = 'POSS'; // 'POSS' or 'EC' or 'OTHERS';

  // バージョン
  systemVersion = '1.0.0';

  // APIホスト
  apiHost = 'https://3gegss.preconfe.net/api/api/';
  // apiHost = 'http://3gegss/api/api/';

  // 個人の各種設定項目
  params = {};

  // 認証状態
  isAuth = false;

  // APIアクセスキー
  accessKey = null;

  // 会員番号
  sno = null;

  // ユーザー情報
  userInfo = {
    name: ''
  };

  // API トークン
  apiToken = '';

  // 各種設定
  settings = {};

  /**
   * 初期化（非同期）
   */
  init = () => {
    const apiToken = this.loadSessionStorage('apiToken');
    if (apiToken) {
      this.setIsAuth(true);
      this.apiToken = apiToken;
    } else {
      this.setIsAuth(false);
    }
    const userInfo = this.loadSessionStorage('userInfo');
    if (userInfo) {
      this.userInfo = userInfo;
    }
    this.loadSettings();
  };

  setUserInfo = (userInfo) => {
    this.userInfo = userInfo;
    this.saveSessionStorage('userInfo', userInfo);
  };

  loadSettings = async () => {
    const { data } = await this.api('setting', {}, 'get');
    if (data.result) {
      data.data.forEach((v) => {
        this.settings[v.key] = v.value;
      });
    }
    console.log(this.settings);
  };

  /**
   * API通信
   */
  api = async (method, params = {}, type = 'post') => {
    const headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    };
    if (this.isAuth) {
      headers.Authorization = `Bearer ${this.apiToken}`;
    }
    const axios = axiosBase.create({
      baseURL: this.apiHost,
      headers,
      responseType: 'json'
    });
    axiosRetry(axios, {
      retries: 3,
      retryDelay: () => 5000 + Math.random() * 5000
    });
    const ret = await axios[type](method, params).catch((e) => null);
    return ret;
  };

  /**
   * CSVアップロード
   */
  csvUpload = async (method, file) => {
    const formData = new FormData();
    formData.append('csv_file', file);
    const headers = {};
    if (this.isAuth) {
      headers.Authorization = `Bearer ${this.apiToken}`;
    }
    const axios = axiosBase.create({
      baseURL: this.apiHost,
      headers,
      responseType: 'json'
    });
    const ret = await axios.post(method, formData, { headers }).catch((e) => null);
    return ret;
  };

  /**
   * ローカルストレージ関連
   */
  saveSessionStorage = (key, value) => {
    sessionStorage.setItem(`@PE:${key}`, !this.isObject(value) ? value : JSON.stringify(value));
  };

  loadSessionStorage = (key) => {
    const value = sessionStorage.getItem(`@PE:${key}`);
    try {
      const ret = JSON.parse(value);
      return ret;
    } catch (e) {
      return value;
    }
  };

  removeSessionStorage = (key) => {
    sessionStorage.removeItem(`@PE:${key}`);
  };

  saveStorage = (key, value) => {
    localStorage.setItem(`@PE:${key}`, !this.isObject(value) ? value : JSON.stringify(value));
  };

  loadStorage = (key) => {
    const value = localStorage.getItem(`@PE:${key}`);
    try {
      const ret = JSON.parse(value);
      return ret;
    } catch (e) {
      return value;
    }
  };

  removeStorage = (key) => {
    localStorage.removeItem(`@PE:${key}`);
  };

  setIsAuth = (flag) => {
    this.isAuth = flag;
    if (window.setIsAuth) {
      window.setIsAuth(flag);
    }
  };

  /**
   * オブジェクト配列並び替え
   */
  objArraySort = (ary, key, order) => {
    let reverse = 1;
    if (order && order.toLowerCase() === 'desc') reverse = -1;
    ary.sort((a, b) => {
      if (a[key] * 1 < b[key] * 1) return -1 * reverse;
      if (a[key] * 1 === b[key] * 1) return 0;
      return 1 * reverse;
    });
  };

  isObject = (value) => value !== null && typeof value === 'object';
}
